// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetOtpQueryVariables = Types.Exact<{
  otp: Types.OtpRequestInput;
}>;

export type GetOtpQuery = {
  __typename?: "Query";
  getOtp: {
    __typename?: "OtpRequest";
    attempts?: number | null;
    contact: string;
    expiresAt?: any | null;
    maxResendCooldown?: number | null;
    otpReason: Types.OtpReason;
    otpStatus: Types.OtpStatus;
    otpType: Types.OtpType;
    plainOTP?: string | null;
    resendCooldown?: number | null;
  };
};

export const GetOtpDocument = gql`
  query GetOtp($otp: OtpRequestInput!) {
    getOtp(otp: $otp) {
      attempts
      contact
      expiresAt
      maxResendCooldown
      otpReason
      otpStatus
      otpType
      plainOTP
      resendCooldown
    }
  }
`;

/**
 * __useGetOtpQuery__
 *
 * To run a query within a React component, call `useGetOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtpQuery({
 *   variables: {
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useGetOtpQuery(
  baseOptions: Apollo.QueryHookOptions<GetOtpQuery, GetOtpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOtpQuery, GetOtpQueryVariables>(
    GetOtpDocument,
    options
  );
}
export function useGetOtpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOtpQuery, GetOtpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOtpQuery, GetOtpQueryVariables>(
    GetOtpDocument,
    options
  );
}
export type GetOtpQueryHookResult = ReturnType<typeof useGetOtpQuery>;
export type GetOtpLazyQueryHookResult = ReturnType<typeof useGetOtpLazyQuery>;
export type GetOtpQueryResult = Apollo.QueryResult<
  GetOtpQuery,
  GetOtpQueryVariables
>;
