import { FC, ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";

interface FormLoadingButtonProps {
  children: ReactNode | ReactNode[];
  isLoading: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const FormLoadingButton: FC<FormLoadingButtonProps> = ({
  children,
  isLoading,
  onClick,
  disabled,
}) => {
  return (
    <LoadingButton
      size="large"
      type="submit"
      variant="contained"
      loading={isLoading}
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: "primary",
        width: "100%",
        paddingY: 2,
        "&:disabled": {
          backgroundColor: "primary.main",
          color: "Background",
        },
        "& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root": {
          width: "22px !important",
          height: "22px !important",
        },
        textTransform: "uppercase",
      }}
    >
      <Typography variant="button">{children}</Typography>
    </LoadingButton>
  );
};

export default FormLoadingButton;
