import {
  addMinutes,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isValid,
  parseISO,
  set,
  startOfDay,
  toDate,
  // eslint-disable-next-line import/no-duplicates
} from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { ar, enUS } from "date-fns/locale";

import appConfig from "~/config/appConfig";

import { Language } from "~/gql/main/types.generated";

export const localizedDateFormat = (
  date: Date,
  lang: string = Language.EN,
  dateFormat: string = appConfig.settings.defaultDateFormat
) =>
  format(date, dateFormat, {
    locale: lang === Language.AR ? ar : enUS,
  }).toString();

export const formatDate = (value: string) => {
  try {
    if (
      !(
        isValid(parseISO(value)) ||
        Object.prototype.toString.call(value) === "[object Date]"
      )
    )
      return;

    return format(new Date(`${value}`), appConfig.settings.defaultDateFormat);
  } catch (error) {
    console.log(error);
  }
};

export const formatDateReverse = (
  value: string,
  DateFormat: string = appConfig.settings.defaultDateFormatReverse
) => {
  try {
    if (
      !(
        isValid(parseISO(value)) ||
        Object.prototype.toString.call(value) === "[object Date]"
      )
    )
      return;

    return format(new Date(`${value}`), DateFormat);
  } catch (error) {
    console.log(error);
  }
};

export const formatTime = (date: string, lang?: string) => {
  try {
    if (
      !(
        isValid(parseISO(date)) ||
        Object.prototype.toString.call(date) === "[object Date]"
      )
    )
      return;

    // return format(new Date(`${date}`), appConfig.settings.defaultTimeFormat);
    return new Date(`${date}`).toLocaleTimeString(lang || "en-US", {
      // timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  } catch (error) {
    console.log(error);
  }
};

const dateWithLeadingZero = (date: number) => {
  if (!date) return;

  return (date < 10 ? "0" : "") + date;
};

export const convertTimeToUtc = (date: Date) => {
  if (!date) return;

  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  return `${dateWithLeadingZero(hour)}:${dateWithLeadingZero(minute)}`;
};

export const convertDateToUtc = (date: Date, time: Date) => {
  if (!date || !time) return;

  // merged the time input and date input because they depend on each other and changing time might affect date
  const DateWithSelectedTime = set(date, {
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
    milliseconds: 0,
  });
  const year = DateWithSelectedTime.getUTCFullYear();
  const month = DateWithSelectedTime.getUTCMonth() + 1; // because it is zero based
  const day = DateWithSelectedTime.getUTCDate();
  const convertedDate = `${dateWithLeadingZero(year)}-${dateWithLeadingZero(
    month
  )}-${dateWithLeadingZero(day)}`;
  const convertedTime = convertTimeToUtc(DateWithSelectedTime);
  return {
    date: convertedDate,
    time: convertedTime,
  };
};

export const minMaxBirthDate = (birthDate: any) => {
  try {
    if (!birthDate) return false;

    const currentDate = new Date();
    return (
      isAfter(new Date(birthDate), new Date(1990, 0, 1)) &&
      isBefore(new Date(birthDate), currentDate)
    );
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const startOfDayUtc = (date: Date) => {
  try {
    return toDate(
      addMinutes(startOfDay(date), -new Date().getTimezoneOffset())
    );
  } catch (error) {
    console.log(error);
  }
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const convertSecondsToHMS = (value: string, addHours = true) => {
  const sec = parseInt(value, 10);
  let hours: string | number = Math.floor(sec / 3600); // get hours
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds: string | number = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${addHours ? `${hours}:` : ""}${minutes}:${seconds}`; // Return is HH : MM : SS
};

export const todayDate = (dateFormat = "yyyy/MM/dd") =>
  format(endOfDay(new Date()), dateFormat);

// export const isDateTimeInRange = (
//   startTime: any,
//   endTime: any,
//   startTimeAddedMinutes?: number,
//   endTimeAddedMinutes?: number
// ) => {
//   const currentTime = new Date();
//   const start = new Date(startTime);
//   const end = new Date(endTime);

//   if (startTimeAddedMinutes && endTimeAddedMinutes) {
//     start.setMinutes(start.getMinutes() + startTimeAddedMinutes);
//     end.setMinutes(end.getMinutes() + endTimeAddedMinutes);
//   }

//   console.log("start====>", start);
//   console.log("end=====>", end);
//   console.log("curr====>", currentTime);
//   // Check if the current time is within the specified range
//   return currentTime >= start && currentTime <= end;
// };

export const isTimeInRange = (
  startTime: any,
  endTime: any,
  startTimeAddedMinutes?: number,
  endTimeAddedMinutes?: number
) => {
  const currentTime = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  if (startTimeAddedMinutes && endTimeAddedMinutes) {
    start.setMinutes(start.getMinutes() + startTimeAddedMinutes);
    end.setMinutes(end.getMinutes() + endTimeAddedMinutes);
  }

  // Extract the time components from the start, end, and current time
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();
  const startHours = start.getHours();
  const startMinutes = start.getMinutes();
  const endHours = end.getHours();
  const endMinutes = end.getMinutes();

  // Convert the time components to minutes for easier comparison
  const currentTimeInMinutes = currentHours * 60 + currentMinutes;
  const startTimeInMinutes = startHours * 60 + startMinutes;
  const endTimeInMinutes = endHours * 60 + endMinutes;

  // console.log("start====>", start);
  // console.log("end=====>", end);
  // console.log("curr====>", currentTime);

  // Check if the current time is within the adjusted time range
  return (
    currentTimeInMinutes >= startTimeInMinutes &&
    currentTimeInMinutes <= endTimeInMinutes
  );
};
