/* eslint-disable id-length */
export const convertNumbers2English = (value: string) => {
  return value
    .replace(/[\u0660-\u0669]/g, (c: string) => {
      return (c.charCodeAt(0) - 0x0660).toString();
    })
    .replace(/[\u06f0-\u06f9]/g, (c: string) => {
      return (c.charCodeAt(0) - 0x06f0).toString();
    });
};
