import { useState } from "react";
import { useRouter } from "next/router";
import { differenceInSeconds } from "date-fns";
import routes from "src/routes/main";

import { getDeviceDetails } from "~/utils/getDeviceDetails";

import { useAppDispatch } from "~/state/hooks";

import {
  Language,
  OtpReason,
  OtpStatus,
  OtpType,
} from "~/gql/main/types.generated";

import {
  GetOtpQuery,
  useGetOtpLazyQuery,
} from "~/modules/login/generated/main/get-otp.generated";
import useCopy from "~/modules/login/locale";
import {
  resetLastLoginAttempt,
  setLastLoginAttempt,
  setOtpExpiryTime,
} from "~/modules/login/state/otpConfigsSlice";

export const useGetOtp = ({ phone }: { phone: string }) => {
  const [remainingSecondsToRetry, setRemainingSecondsToRetry] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();
  const { translate } = useCopy();
  const router = useRouter();

  const [getOtp, { loading: isLoading }] = useGetOtpLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data: GetOtpQuery) {
      if (data?.getOtp) {
        const { otpStatus } = data.getOtp;
        if (otpStatus === OtpStatus.PENDING) {
          setErrorMessage("");
          const timeToExpire = differenceInSeconds(
            new Date(data.getOtp.expiresAt),
            new Date()
          );
          dispatch(setOtpExpiryTime(timeToExpire));
          dispatch(resetLastLoginAttempt());

          if (phone) {
            const url = `${routes.verifyOtp}?phone=${phone}`;
            router.push(url);
          }
        } else {
          setErrorMessage(translate(`error.status.${otpStatus}`));
          const timer =
            // eslint-disable-next-line no-nested-ternary
            otpStatus === OtpStatus.COOLDOWN
              ? data?.getOtp?.resendCooldown
              : otpStatus === OtpStatus.MAX_RESEND_PER_DAY
              ? data?.getOtp?.maxResendCooldown
              : -1;

          setRemainingSecondsToRetry(timer || -1);

          // Errors that require a timer, redirect the user to the initial screen
          // so we need to store the data of the last error that occurred
          if (timer && timer > 0)
            dispatch(
              setLastLoginAttempt({
                phone,
                errorCode: otpStatus,
                attempts: 0,
                timer,
                timestamp: JSON.stringify(new Date()),
              })
            );
        }
      }
    },
    onError(error) {
      setErrorMessage(error.message);
    },
  });

  const constructQueryVariables = () => {
    return {
      variables: {
        otp: {
          contact: `+${phone}`,
          deviceDetails: getDeviceDetails(),
          lang: Language.EN,
          otpReason: OtpReason.LOGIN,
          otpType: OtpType.SMS,
        },
      },
    };
  };

  return {
    getOtp,
    isLoading,
    constructQueryVariables,
    remainingSecondsToRetry,
    setRemainingSecondsToRetry,
    errorMessage,
    setErrorMessage,
  };
};
