/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from "react";

import { getLocalize, I18nOptions } from "~/utils/i18n/localize";

import { useAppSelector } from "~/state/hooks";

import { OtpStatus } from "~/gql/main/types.generated";

const labels = {
  "field.phone": {
    en: "Phone",
    ar: "الهاتف",
  },
  "label.logIn": {
    en: "Log in",
    ar: "تسجيل الدخول",
  },
  "label.note.logIn": {
    en: "Welcome back! Just enter the phone number and you will be in",
    ar: "مرحبا بعودتك! فقط ادخل رقم هاتفك لتسجيل الدخول",
  },
  "label.verifyPhoneNumber": {
    en: "Verify phone number",
    ar: " تحقق من رقم الهاتف",
  },
  "label.note.verifyPhoneNumber": {
    en: "Please enter the verification code we sent to mobile no. +{{phone}}",
    ar: "الرجاء إدخال رمز التحقق الذي تم إرساله إلي رقم الهاتف {{phone}}+",
  },
  "label.otpExpiresIn": {
    en: "OTP expires in {{time}} minutes",
    ar: "تنتهي صلاحية رمز التحقق خلال {{time}} دقائق",
  },
  "label.otpExpired": {
    en: "OTP expired",
    ar: "انتهت صلاحية رمز التحقق",
  },
  "button.resendCode": {
    en: "Resend code",
    ar: " إعادة إرسال الرمز",
  },
  [`error.status.${OtpStatus.BAD_CONTACT}`]: {
    en: "Invalid phone number",
    ar: "",
  },
  [`error.status.${OtpStatus.NO_USER}`]: {
    en: "This phone number is not registered with us, please enter a different number",
    ar: "رقم الهاتف هذا غير مسجل لدينا، برجاء إدخال رقم مختلف",
  },
  [`error.status.${OtpStatus.COOLDOWN}`]: {
    en: "You have recently requested an OTP.",
    ar: "",
  },
  [`error.status.${OtpStatus.MAX_RESEND_PER_DAY}`]: {
    en: "You have reached the maximum number of attempts for requesting an OTP.",
    ar: "تم الوصول للحد الأقصى لمحاولات إعادة الإرسال.",
  },
  [`error.status.${OtpStatus.NO_OTP}`]: {
    en: "No new OTP was requested for this phone number. Please request one",
    ar: "",
  },
  [`error.status.${OtpStatus.PENDING}`]: {
    en: "Invalid OTP",
    ar: "رمز التحقق غير صحيح",
  },
  [`error.status.${OtpStatus.MAX_ATTEMPTS}`]: {
    en: "You've used all {{maxAttempts}} attempts.",
    ar: "",
  },
  "error.phoneNumberMin": {
    en: `Phone number must be at least {{phoneMinLength}} digits`,
    ar: `يجب أن يكون رقم الهاتف أكثر من {{phoneMinLength}} أرقام`,
  },
  "label.tryAgainIn": {
    en: "Please try again in {{time}}",
    ar: "",
  },
};

export const getCopy = function (
  key: string,
  options?: I18nOptions,
  lang?: "ar" | "en"
): string {
  return getLocalize(labels, key, options, lang);
};
export default function useCopy() {
  const lang = useAppSelector((state) => state.settings.lang);
  const translate = useCallback(
    (key: string, options?: I18nOptions) =>
      getCopy(key, options, (lang?.toLowerCase() as "ar" | "en") || "en"),
    [lang]
  );
  return { translate };
}
