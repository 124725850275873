import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";

import { millisecondsInSecond } from "~/utils/constants/dateTime";
import { convertSecondsToHMS } from "~/utils/dateTime";

import useCopy from "~/modules/login/locale";

type ErrorAlertProps = {
  remainingSecondsToRetry: number;
  setRemainingSecondsToRetry: Dispatch<SetStateAction<number>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  onStart?: () => void;
  onComplete?: () => void;
};

const ErrorAlert = ({
  remainingSecondsToRetry,
  setRemainingSecondsToRetry,
  errorMessage,
  setErrorMessage,
  onStart,
  onComplete,
}: ErrorAlertProps) => {
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const { translate } = useCopy();

  useEffect(() => {
    if (remainingSecondsToRetry === 0) {
      // Important to reset timer to -1 to differentiate between errors that require a timer and errors that don't.
      // If it was kept at 0 and in case the error required no timer, this condition will be true and the error message will be
      // set to "" and therefore it won't be displayed to the user
      setRemainingSecondsToRetry(-1);
      setErrorMessage("");

      onComplete && onComplete();
    } else if (remainingSecondsToRetry > 0) {
      onStart && onStart();

      if (interval.current) clearInterval(interval.current);
      interval.current = setInterval(
        () => setRemainingSecondsToRetry((seconds: number) => seconds - 1),
        millisecondsInSecond
      );
    }

    return () => {
      if (interval.current) clearInterval(interval.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSecondsToRetry]);

  return (
    <Alert
      sx={{
        marginBottom: (theme) => theme.spacing(1.5),
        textAlign: "start",
      }}
      onClose={() => setErrorMessage("")}
      severity="error"
    >
      {errorMessage}{" "}
      {remainingSecondsToRetry > 0 &&
        translate("label.tryAgainIn", {
          time: convertSecondsToHMS(remainingSecondsToRetry.toString(), false),
        })}
    </Alert>
  );
};

export default ErrorAlert;
